.credit-flags {
    display: flex;
    margin-bottom: 15px;
}

.card-item {
    & + & {
        margin-left: 5px;
    }

    img {
        width: 70%;
    }
}

.card-form {
    margin-top: 20px;
}

.form-control {
    border-radius: 5px;
}

.input-group-addon {
    background-color: #fff;
    border-radius: 5px;
}

.payment-title {
    font-size: 24px;
    position: relative;

    span {
        margin-left: 10px;
    }

    .fa {
        font-size: 24px;
    }

    &.collapsed {
        font-size: 28px;
        .fa {
            font-size: 36px;
        }
        .payment-chevron {
            font-size: 24px;
            transform: translateY(-50%);
        }
    }
}

.payment-chevron {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    transition: transform .3s;
}

.plan-box {
    margin: 0 0 20px 0;
}
